import { Link, graphql, navigate } from 'gatsby';
import Helmet from 'react-helmet';
import Img, { FluidObject } from 'gatsby-image';
import Layout from '../components/Layout';
import React, { useCallback, useEffect, useState } from 'react';
import SEO from '../components/SEO';
import styled, { css } from 'styled-components';
import useWindowSize from '../utils/useWindowSize';

interface Props {
  data: {
    trip: {
      date: string;
      images: {
        path: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
        slug: string;
        title: string;
      }[];
      slug: string;
      title: string;
    };
  };
  pageContext: {
    imageIndex: number;
    prevSlug: string;
    nextSlug: string;
  };
}

const imageHeight = 'calc(100vh - 200px)';

const getImageToPreload = (srcSet: string, windowWidth: number) => {
  const sizes = srcSet.split(/,\n/);

  let url = '';

  // Find the largest image in the srcset that's not larger than the screen
  for (let i = 0; i < sizes.length; i++) {
    const [image, size] = sizes[i].split(' ');
    const width = parseInt(size.replace('w', ''), 10);
    if (width <= windowWidth) {
      url = image;
    }
  }

  return url;
};

export default function Photo({
  data: { trip },
  pageContext: { imageIndex, prevSlug, nextSlug },
}: Props) {
  const { width } = useWindowSize();

  const image = trip.images[imageIndex];
  const prevImage =
    imageIndex > 0
      ? getImageToPreload(
        trip.images[imageIndex - 1].path.childImageSharp.fluid.srcSetWebp,
        width
      )
      : undefined;
  const nextImage =
    imageIndex < trip.images.length - 1
      ? getImageToPreload(
        trip.images[imageIndex + 1].path.childImageSharp.fluid.srcSetWebp,
        width
      )
      : undefined;

  useKeyPress('ArrowLeft', () => {
    if (prevSlug) {
      navigate(prevSlug);
    }
  });

  useKeyPress('ArrowRight', () => {
    if (nextSlug) {
      navigate(nextSlug);
    }
  });

  return (
    <>
      <Helmet>
        {prevImage && (
          <link rel="preload" href={prevImage} as="image" type="image/jpeg" />
        )}
        {nextImage && (
          <link rel="preload" href={nextImage} as="image" type="image/jpeg" />
        )}
      </Helmet>
      <Layout
        dark
        subtitle={
          <TripLink to={`/travel/${trip.slug}`}>
            {trip.title}, {trip.date}
          </TripLink>
        }
      >
        <SEO title={`${image.title} | ${trip.title}, ${trip.date}`} />
        <Image
          alt={image.title}
          durationFadeIn={1000}
          fluid={image.path.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          placeholderStyle={{ display: 'none' }}
        />
        <ImageTitle screenSize="small">{image.title}</ImageTitle>
        <ImageDetails>
          <Navigator>
            {prevSlug && (
              <NavigatorButton onClick={() => navigate(prevSlug)}>
                <NavigatorButtonText>&larr; Prev</NavigatorButtonText>
              </NavigatorButton>
            )}
          </Navigator>
          <ImageTitle screenSize="large">{image.title}</ImageTitle>
          <Navigator>
            {nextSlug && (
              <NavigatorButton onClick={() => navigate(nextSlug)}>
                <NavigatorButtonText>Next &rarr;</NavigatorButtonText>
              </NavigatorButton>
            )}
          </Navigator>
          {/* <ImageCount
          {imageIndex + 1}/{trip.images.length}
        </ImageCount> */}
        </ImageDetails>
      </Layout>
    </>
  );
}

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${imageHeight};
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Image = styled(Img)`
  height: ${imageHeight};
`;

const TripLink = styled(Link)`
  color: inherit;

  &:active,
  &:hover {
    box-shadow: 0 1px 0 0 #fff;
  }
`;

const ImageDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 10px;
`;

const ImageTitle = styled.h3<{ screenSize: 'small' | 'large' }>`
  color: #fff;
  flex: 1;
  margin: 0;
  padding: 0 20px;
  text-align: center;

  ${({ screenSize }) =>
    screenSize === 'small' &&
    css`
      @media (min-width: 1024px) {
        display: none;
      }
    `}

  ${({ screenSize }) =>
    screenSize === 'large' &&
    css`
      @media (max-width: 1023px) {
        display: none;
      }
    `}
`;

const Navigator = styled.div`
  flex: 0 0 100px;
`;

const NavigatorButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 2px;
  user-select: none;

  &:hover,
  &:active {
    box-shadow: 0 1px 0 0 #fff;
  }
`;

const NavigatorButtonText = styled.h4`
  color: #fff;
  margin: 0;
`;

const ImageCount = styled.h4`
  color: #fff;
`;

const PlaceholderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Placeholder = styled.div<{
  aspectRatio: number;
  imageUrl: string;
  isLoaded: boolean;
}>`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 0;
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  height: calc(100vw / ${({ aspectRatio }) => aspectRatio});
  max-height: 100%;
  transition-delay: 500ms;
  width: 100vw;
`;

function useKeyPress(targetKey: string, onPress: () => void) {
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      onPress();
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
}

export const query = graphql`
  query PhotoTemplate($tripSlug: String!) {
    trip: tripsYaml(slug: { eq: $tripSlug }) {
      date
      images {
        path {
          childImageSharp {
            fluid(fit: CONTAIN, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        slug
        title
      }
      slug
      title
    }
  }
`;
